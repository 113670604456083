<template>
  <svg height="10" width="10" xmlns="http://www.w3.org/2000/svg" version="1.1" :viewBox="dynamicString">
    <defs>
      <pattern id="uthogd" patternUnits="userSpaceOnUse" width="100%" height="100%" viewBox="0 0 42.08 44">
        <polygon points="15.99 9.23 13.39 2 4.26 2 1.9 9.2 0 8.58 2.81 0 14.8 0 17.87 8.55 15.99 9.23" fill="#432f19"/>
        <polygon points="35.99 29.23 33.39 22 24.26 22 21.9 29.2 20 28.58 22.81 20 34.8 20 37.87 28.55 35.99 29.23" fill="#432f19"/>
      </pattern>

      <pattern id="skog" patternUnits="userSpaceOnUse" width="100%" height="100%" viewBox="0 0 42.06 43.2">
        <polygon points="8 12.77 4.66 5.27 1.87 12.71 0 12.01 4.51 0 9.83 11.96 8 12.77" fill="#432f19"/>
        <polygon points="28 33.77 24.66 26.27 21.87 33.71 20 33.01 24.51 21 29.83 32.95 28 33.77" fill="#432f19"/>
      </pattern>
    </defs>
  </svg>
</template>
<script>
export default {
  props: {
    zoom: {
      type: Number
    },
    svg: {
      type: Object
    }
  },
  computed: {
    dynamicString: function() {
      return '0 0 ' + this.dynamicView + ' ' + this.dynamicView;
    },
    dynamicView: function() {
      //percent = ((input - min) * 100) / (max - min)
      let zoom = this.zoom < 9 ? 9 : this.zoom,
          inv_level = 20 - zoom,
          result = Math.pow(2, inv_level) * 1128.497220;

      let newView = result / 250000;
      // console.log('Dynamic zoom', newView);
      return newView;
    }
  }
}
</script>
