import "@/css/default.scss";
import "@/css/slider.scss"
import "virtual:svg-icons-register";

import {createApp} from 'vue'
// import the root component App from a single-file component.
import App from '../vue/App.vue'

const app = createApp(App)
app.mount('#app')
