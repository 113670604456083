<template>
  <div class="timeline-wrapper" v-if="timeline.value">
    <vue-slider v-model="timeline.value" :min="timeline.min" :max="timeline.max" @change="$emit('slider-change', timeline.value)" :tooltip="'always'" :marks="[timeline.min,timeline.max]">
      <template v-slot:step="{ label, active }">
        <div :class="['aarstall', { active }]"></div>
      </template>
    </vue-slider>
  </div>
</template>
<script>
import VueSlider from 'vue-slider-component';

export default {
  components: {
    VueSlider
  },
  props: {
    timeline: {
      type: Object,
      required: true
    }
  }
}
</script>
