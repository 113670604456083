<template>
  <main :class="[{ skjulIkon: showIcon == false , showHighlight: current.highlighted && autoRun, visMeny: showMenu, idle: idle}]">
    <div id="loading" v-show="loadingProsent" :style="{ width: loadingProsent + '%' }"></div>
    <div id="map" class="map" :class="['zoom_'+current.zoom, kategorierClass]"></div>
    <div id="controls">
      <slider :timeline="timeline" @slider-change="sliderChange" v-if="timeline.value"/>
    </div>
    <div height="0" width="0" id="patternWrapper">
      <pattern-svg :zoom="current.zoom"/>
    </div>
    <div id="meny">
      <div class="topp">
        <ul v-if="current.historyId" id="history">
          <h3 class="open-sans">{{ translations[language].historier }}</h3>
          <li v-for="history in histories" class="garamond" :class="{ active: current.historyId == history.id }" @click.prevent="current.historyId = history.id">
            {{ history.title[language] }}
          </li>
        </ul>
        <ul v-if="current.storyId" id="story">
          <div v-if="lasterHistorie" class="laster">
            <svg>
              <use href="#icon-loading"/>
            </svg>
            <span>Laster</span>
          </div>
          <div v-else>
            <h3 class="open-sans">{{ translations[language].kapitler }}</h3>
            <li v-for="story in history.story" :class="{ active: current.storyId == story.id }" @click="storyChange(story.id); showStoryText = true;">
              {{ story.name[language] }}
            </li>
          </div>
        </ul>
      </div>
      <div class="bunn">
        <div>
          <select class="form-check" v-model="language">
            <option value="nb" selected>Norsk</option>
            <option value="en">Engelsk</option>
          </select>
        </div>
        <div>
          <div class="form-check" v-if="current.storyId">
            <label class="form-check-label">
              <input class="form-check-input" type="checkbox" v-model="autoRun" @change="runStory(true)"/>
              <span>Autorun</span>
            </label>
          </div>
        </div>
      </div>
    </div>
    <ul id="forklaring">
      <template v-for="ikon in history.icons">
        <li>
          <img :src="ikon.url"/>
          <div>
            <strong>{{ ikon.translations.nb }}</strong>
            <span>{{ ikon.translations.en }}</span>
          </div>
        </li>
      </template>
    </ul>
    <div class="design top">
      <a id="selected-story" v-on:click="showMenu = !showMenu">
        <span v-if="current.historyName">{{ history.title[language] }}</span>
        <svg v-else>
          <use href="#icon-loading"/>
        </svg>
        <i class="arrow down"></i>
      </a>
    </div>
    <div class="design bottom">
      <transition name="down">
        <div id="content" v-if="current.story && current.story.text[this.language] && showStoryText">
          <div class="content" :class="{ showPicture: current.story.image, showText: current.story.text[this.language] }">
            <figure v-if="current.story.image">
              <img v-bind:src="current.story.image">
            </figure>
            <div class="text" :class="{ full: !current.story.image}">
              <div v-for="(story, key) in current.story.text" :class="key">
                <h2>{{ current.story.name[key] }}</h2>
                <div v-html="story" class=""></div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <transition name="fade">
      <div id="pointContent" v-if="pointContent">
        <a class="lukk" v-on:click="pointContent = null">X</a>
        <div class="content">
          <div class="text">
            <h1 class="h3">{{ pointContent.title[this.language] }}</h1>
            <div v-html="pointContent.body[this.language]"></div>
          </div>
        </div>
      </div>
    </transition>
    <div v-html="svgCss"></div>
  </main>
</template>
<script>
import "leaflet/dist/leaflet.css";
import L from 'leaflet';

import 'mapbox-gl/dist/mapbox-gl.css';
import 'mapbox-gl';
import 'mapbox-gl-leaflet';

import Slider from './components/slider.vue';
import PatternSvg from './components/pattern.vue';

import axios from 'axios';
import hovedSvg from '@/assets/hoved.svg?raw';

import IdleJs from "idle-js";

const objectMap = (obj, fn) => Object.fromEntries(Object.entries(obj).map(([k, v], i) => [k, fn(v, k, i)]));

export default {
  components: {
    Slider,
    PatternSvg,
  },
  data: function() {
    return {
      currentRoute: window.location.pathname.replace("/", ""),
      idle: false,
      lasterHistorie: false,
      loadingProsent: 0,
      timeouts: [],
      map: null,
      mapLayer: null,
      translations: [],
      language: 'nb',
      autoRun: false,
      showStoryText: false,
      showIcon: true,
      showMenu: false,
      showText: false,
      svgCss: null,
      svg: null,
      pointContent: null,
      forklaringer: [],
      timeline: {
        value: null,
        min: 0,
        max: 1,
        marks: []
      },
      current: {
        historyName: null,
        historyId: null,
        storyId: null,
        story: null,
        highlighted: null,
        zoom: null,
      },
      histories: [],
      history: [],
    }
  },
  computed: {
    timelineClass: function() {
      return '' + this.timeline.value;
    },
    kategorierClass: function() {
      if (!this.history.categories) {
        return '';
      }

      let string = '';
      this.history.categories.forEach(function(item) {
        console.log(item);
        string += 'kat_' + item + ' ';
      });
      return string.replace(/,\s*$/, "");
    }
  },
  watch: {
    'autoRun': function(val) {
      this.showStoryText = val;
      this.pointContent = null;
      if (!val && this.current.highlighted) {
        this.current.highlighted.forEach((marker) => {
          marker.removeFrom(this.map);
        });
      }
      this.clearSetTimeout();

      window.location.hash = val ? 'auto' : '';
      // console.log('autorun', val, this.showStoryText);
    },
    'pointContent': function(point) {
      // console.log(point);
    },
    'current.story': function(story) {
      // let high = story.filter((id) => id === story.highlighted[0]);
      this.pointContent = null;
      if (this.current.highlighted) {
        this.current.highlighted.forEach((marker) => {
          marker.removeFrom(this.map);
        });
      }

      let highlighted = [],
          markers = [];
      story.highlighted.forEach((id) => {
        // console.log(id);
        let highlight = this.history.mapelements.filter(feature => feature.id === id)[0] || null;
        if (highlight) {
          let el = highlight.leafletObject;
          let marker = L.marker(highlight.coords, {
            icon: L.divIcon({
              className: 'highlight',
              iconAnchor: [31, 31],
              iconSize: [60, 60]
            })
          });
          highlighted.push(marker);
          marker.addTo(this.map);

          /*el.openTooltip();

          setTimeout(() => {
            el.closeTooltip();
          }, 2000);*/
        }
      });
      // let high = this.history.mapelements.filter(feature => feature.id === story.highlighted);
      // console.log('change', highlighted);
      this.current.highlighted = highlighted.length ? highlighted : null;
    },
    'current.historyId': function(val) {
      // console.log(val);
      this.pointContent = null;
      this.initHistory();
    },
    'timeline.value': function(value) {
      // console.log(value);
      this.svgCss = '<style>' +
          this.dynamicCss(1860, 2030, '.kat_avskoging .avskoging.siste', ' .kat_avskoging .avskoging') +
          this.dynamicCss(1749, 1817, '.kat_circumferensen .circumferensen.forste') +
          this.dynamicCss(1751, 1849, '.kat_circumferensen .circumferensen.andre') +
          this.dynamicCss(1752, 1817, '.kat_circumferensen .circumferensen.tredje') +
          this.dynamicCss(1850, 2030, '.kat_circumferensen .circumferensen.fjerde') +
          this.dynamicCss(2010, 2030, '.kat_privilegieomradet .privilegieomradet.forste') +
          this.dynamicCss(2010, 2030, '.kat_privilegieomradet .privilegieomradet.andre') +
          this.dynamicCss(1875, 2030, '.kat_jernbane .jernbane.y_1875') +
          this.dynamicCss(1876, 2030, '.kat_jernbane .jernbane.y_1876') +
          this.dynamicCss(1877, 2030, '.kat_jernbane .jernbane.y_1877') +
          '.maks_omrade' +
          '{display: block !important;}</style>';
      this.timelineChange();
    },
  },
  mounted() {
    /* Code to run when app is mounted */
    this.translations['nb'] = {
      "historier": "Historier",
      "kapitler": "Kapitler"
    };
    this.translations['en'] = {
      "historier": "Histories",
      "kapitler": "Chapter"
    };
    this.initMap();
    this.initHistories(this.currentRoute);
    const _this = this;
    new IdleJs({
      idle: 5000, // idle time in ms
      events: ['mousemove', 'keydown', 'mousedown', 'touchstart'], // events that will trigger the idle resetter
      onIdle: () => {
        // console.log('skjul');
        this.idle = true;

      }, // callback function to be executed after idle time
      onActive: () => {
        // console.log('vis');
        this.idle = false;
      }, // callback function to be executed after back form idleness
    }).start();
    // console.log(window.location.hash);
    if (window.location.hash === '#auto') {
      setTimeout(() => {
        this.autoRun = true;
        this.runStory(true);
      }, 3000);
    }
  },
  methods: {
    dynamicCss(fra, til, klasse, ikkeKlasse) {
      let css = '';
      if (fra <= this.timeline.value && this.timeline.value <= til) {
        css = klasse + ',';
      } else if (ikkeKlasse) {
        css = ikkeKlasse + '.y_' + this.timeline.value + ' , ';
      }
      return css;
    },
    clearSetTimeout() {
      for (let i = 0; i < this.timeouts.length; i++) {
        clearTimeout(this.timeouts[i]);
      }
    },
    /* Any app-specific functions go here */
    sliderChange(value) {
      // console.log('Timeline', value);
      this.autoRun = false;

      // this.showStoryText = true;
    },
    timelineChange() {
      // console.log(this.history.mapelements[0].leafletObject._layers)
      this.history.mapelements.forEach((feature) => {
        let tidslinje = feature.timeline,
            el = feature.leafletObject,
            isGeoJson = typeof el._layers === 'object',
            fra = tidslinje[0],
            inaktiv = tidslinje[1],
            til = tidslinje[2];

        let layers = Object.values(isGeoJson ? objectMap(el._layers, (obj) => obj._path || null) : [el.getElement()]).filter(item => item);

        const skjul = (fra && this.timeline.value < fra) || (til && this.timeline.value > til),
            isInaktive = (fra && this.timeline.value > fra) && (inaktiv && this.timeline.value > inaktiv);

        layers.forEach((item) => {
          item.classList.remove("show", "inaktiv");
          if (!skjul) {
            item.classList.add("show");
          }
          if (isInaktive) {
            item.classList.add("inaktiv");
          }
        });

        if (skjul) {
          if (feature.type !== 'geojson') {
            el.closeTooltip();
          }
        }

        // if ((fra && this.timeline.value < fra) || (til && this.timeline.value > til)) {
        //
        //   if (feature.type !== 'geojson') {
        //     el.closeTooltip();
        //   }
        // } else {
        //   const vis = (fra && this.timeline.value < fra) || (til && this.timeline.value > til),
        //   isInaktive = (fra && this.timeline.value > fra) && (inaktiv && this.timeline.value > inaktiv);
        //   layers.forEach((item) => {
        //     item.classList.add("show")
        //   });
        // }
      });
    },
    historyChange(value) {
      // console.log('History', value);
      this.autoRun = false;
      this.pointContent = null;
      // this.showStoryText = false;
      // this.pointContent = null;
      this.history.storyId = value;
      this.runStory(false);
    },
    storyChange(value) {
      this.pointContent = null;
      // console.log('Story', value);
      this.autoRun = false;

      // this.showStoryText = false;
      this.current.storyId = value;
      this.runStory(false);
    },
    initMap() {
      this.map = L.map('map', {
        trackResize: false,
        zoomControl: false,
      }).on('dragstart', () => {
        this.autoRun = false;
        this.showStoryText = false;
        this.clearSetTimeout();
      }).on('zoom', () => {
        // this.showStoryText = true;
        this.current.zoom = parseInt(this.map.getZoom());
        // console.log(this.svg ? this.svg.getElement().style.height : null);

        // this.showIcon = currentZoom >= 8;
      });


      // Mapbox
      // Vanlig raster tile
      const token = 'pk.eyJ1IjoibXVzdGFzaiIsImEiOiJjazBwMmFwN20wZmN1M3BzOGZrNDF1cTJhIn0.GJsi6274OZt9J8CjOc_puw';
      // let tileStyle = 'https://api.mapbox.com/v4/mustasj.ck0f19fae03zd1co70gkxruel/{z}/{x}/{y}.mvt?access_token=' + token;
      // this.mapLayer = L.tileLayer(tileStyle).addTo(this.map);

      //SVG tiles
      // mapbox://styles/mustasj/ck0f19fae03zd1co70gkxruel

      this.mapLayer = L.mapboxGL({
        style: 'mapbox://styles/mustasj/ckq0l2std2puh17ple672ivt0',
        accessToken: token
      }).addTo(this.map);

      // this.mapLayer = L.maplibreGL({
      //   style: 'https://api.mapbox.com/styles/v1/mustasj/ck0f19fae03zd1co70gkxruel/tiles/256/{z}/{x}/{y}?access_token=' + token
      // }).addTo(this.map);

      this.map.setView([62.578049, 11.391950], 13);

      setTimeout(() => {
        this.map.invalidateSize(true);
      }, 200);
      let parser = new DOMParser();
      let doc = parser.parseFromString(hovedSvg, "image/svg+xml");
      let svgElement = doc.firstChild;
      svgElement.id = "hovedSvg";
      // console.log(hovedSvg)
      this.svg = L.svgOverlay(svgElement, [[63.172083, 9.479656], [61.386157, 13.698878]]).addTo(this.map);
    },
    initMarkers() {
      const markermapelements = this.history.mapelements.filter(feature => feature.type === 'marker');
      const polygonmapelements = this.history.mapelements.filter(feature => feature.type === 'polygon');
      const geojsonmapelements = this.history.mapelements.filter(feature => feature.type === 'geojson');

      // test for å se ikon
      let icons = {};
      // console.log(this.history.icons);
      this.history.icons.forEach((ikon) => {
        // console.log(ikon);
        Object.assign(icons, {
          [ikon.name]: L.icon({
            iconUrl: ikon.url,
            iconSize: [ikon.size.width, ikon.size.height],
            iconAnchor: [ikon.size.y, ikon.size.x],
            // popupAnchor: [-3, -76],
            // shadowUrl: 'my-icon-shadow.png',
            // shadowSize: [68, 95],
            // shadowAnchor: [22, 94]
          })
        });
      });

      markermapelements.forEach((feature) => {
        let ikonHandle = feature.icon || 'default';
        let obj = null;

        if (ikonHandle != 'default') {
          let ikon = icons[feature.icon],
              ikonSize = ikon.options.iconSize,
              anchor = ikon.options.iconAnchor || [0, 0],
              offsetX = 0,
              offsetY = (-anchor[1] / 2) - (ikonSize[1] / 2);
          obj = L.marker(feature.coords, {
            icon: ikon,
            title: feature.name[this.language],
          }).bindTooltip(feature.name[this.language], {permanent: false, className: "ikon-tooltip", direction: 'top', offset: [offsetX, offsetY]});
        } else {
          obj = L.marker(feature.coords, {
            icon: L.divIcon({
              className: 'default-marker',
              iconAnchor: [20, 20],
              iconSize: [40, 40]
            }),
            title: feature.name[this.language],
          }).bindTooltip(feature.name[this.language], {permanent: false, className: "default-tooltip", direction: 'top', offset: [0, -25]});
        }

        feature.leafletObject = obj;
      });

      polygonmapelements.forEach((feature) => {
        feature.leafletObject = L.polygon(feature.coords);
      });

      geojsonmapelements.forEach((feature) => {
        let klasse = 'timeline-animation';
        if (feature.utseende) {
          klasse += ' utseende-' + feature.utseende;
        }

        feature.leafletObject = L.geoJSON(JSON.parse(feature.geojson), {
          style: function(feature) {
            let lineKlasse = feature.geometry.type === 'LineString' ? ' line' : '';
            // console.log(feature.geometry.type);
            return {
              className: klasse + lineKlasse
            }
          },
          // onEachFeature: function(test, layer) {
          //     console.log(test, layer);
          // }
        });
      });

      this.history.mapelements.forEach((feature) => {
        let obj = feature.leafletObject;
        if (feature.type !== 'geojson') {
          obj.on('click', () => {
            // console.log('marker click', feature);
            // this.showStoryText = true;
            let marker = obj.getElement(),
                tooltip = obj.getTooltip().getElement(),
                currentActive = marker.classList.contains('clicked');

            let clicked = document.querySelectorAll(".clicked");
            for (let i = 0; i < clicked.length; i++) {
              clicked[i].classList.remove('clicked');
            }

            if (!currentActive) {
              // console.log('add clicked', feature.body);
              marker.classList.add("clicked");
              obj.openTooltip();
              tooltip.classList.add("clicked");
              this.hentPunkt(feature.id);
            } else {
              this.pointContent = null;
            }

            this.autoRun = false;
          });

          // console.log(obj.getTooltip());

          obj.getTooltip().on('onClick', () => {
            alert("Label clicked!");
          });
        }

        obj.addTo(this.map);

        if (feature.type !== 'geojson') {
          // console.log(obj.tool._tooltip.className);
          obj.getElement().classList.add("timeline-animation");
        }
      });
    },
    hentPunkt(feature) {
      let id = feature.id || feature;
      this.pointContent = null;
      axios.get(`${siteUrl}punkt/${id}.json`)
          .then((response) => {
            this.pointContent = response.data;
          }).catch(e => {
        console.error(e);
      });

    },
    initHistories(id) {
      axios.get(`${siteUrl}historier.json`)
          .then((response) => {
            this.histories = response.data.data;
            let history = this.histories.filter(function(h) {
              return h.id == id;
            })[0] || this.histories[0];

            this.current.historyId = history.id;
            // setTimeout(() => {
            //
            // }, 1000);

          }).catch(e => {
        // this.errors.push(e)
        console.error(e);
      });
    },
    initHistory() {
      this.lasterHistorie = true;
      this.removeHistory();
      axios.get(`${siteUrl}historie/${this.current.historyId}.json`)
          .then((response) => {
            this.autoRun = false;

            this.history = response.data;
            this.current.historyName = this.history.title;

            // this.history = response.data.data[0];
            // console.log(this.history);
            history.pushState(null, null, '/' + this.current.historyId);
            this.addHistory();
          }).catch(e => {
        // this.errors.push(e)
        console.error(e);
      }).then(() => {
        this.lasterHistorie = false;
      });

    },
    removeHistory() {
      if (typeof (this.history.mapelements) === 'object') {
        // console.log(this.history.mapelements.length);
        this.history.mapelements.forEach((feature) => {
          if (typeof feature.leafletObject !== 'undefined') {
            feature.leafletObject.removeFrom(this.map);
          }
        });
      }
    },
    addHistory() {
      this.map.flyTo(this.history.start.coords, this.history.start.zoom);

      let marks = [],
          lat = [],
          lng = [],
          zoom = [];

      this.history.story.forEach((story) => {
        // console.log(story);
        marks.push(story.timeline);
        lat.push(story.coords[0]);
        lng.push(story.coords[1]);
        zoom.push(story.zoom);
      });

      this.history.mapelements.forEach((elements) => {
        // console.log(story);
        let tidslinje = elements.timeline;
        if (tidslinje[0]) {
          marks.push(tidslinje[0]);
        }
        if (tidslinje[1]) {
          marks.push(tidslinje[1]);
        }
        if (typeof elements.coords !== 'undefined') {
          lat.push(elements.coords[0]);
          lng.push(elements.coords[1]);
        }

        // zoom.push(story.zoom);
      });

      let minLat = Math.min.apply(null, lat) - 0.5,
          minLng = Math.min.apply(null, lng) - 0.75,
          minZoom = Math.min.apply(null, zoom),
          maxLat = Math.max.apply(null, lat) + 0.5,
          maxLng = Math.max.apply(null, lng) + 0.75,
          maxZoom = Math.max.apply(null, zoom);

      let bbox = L.latLngBounds(L.latLng(minLat, minLng), L.latLng(maxLat, maxLng));
      //cache alle tile innenfor bbbox
      // this.mapLayer.seed(bbox, minZoom, maxZoom);

      //Sett bouncxe
      // this.map.setMaxBounds(bbox);
      //Sett min zoom
      // this.map.setMinZoom(10);

      // Display seed progress on console
      // this.mapLayer.on('seedstart', function(seedData) {
      // 	this.loading = true;
      // 	console.log('Cache seeding starting');
      // });
      //
      // this.mapLayer.on('seedprogress', (seedData) => {
      // 	let percent = 100 - Math.floor(seedData.remainingLength / seedData.queueLength * 100);
      // 	if (this.loadingProsent != percent) {
      // 		this.loadingProsent = percent;
      // 		console.log('Seeding ' + percent + '% done');
      // 	}
      // });
      //
      // this.mapLayer.on('seedend', function(seedData) {
      // 	this.loading = false;
      // 	console.log('Cache seeding complete');
      // });

      let finnesMarks = marks.length > 0;

      this.timeline.marks = finnesMarks ? marks : [];
      this.timeline.min = finnesMarks ? Math.min.apply(null, marks) : null;
      this.timeline.max = finnesMarks ? Math.max.apply(null, marks) : null;
      this.timeline.value = this.timeline.min;

      this.current.storyId = this.history.story.length > 0 ? this.history.story[0].id : null;

      // console.log(marks, this.timeline.value, this.timeline.min, this.timeline.max);
      this.initMarkers();
      this.timelineChange();
    },
    // showContent(body, remove) {
    // 	if (!body || !body[this.language]) {
    // 		this.current.info = null;
    // 	} else if (body) {
    // 		this.current.info = body;
    // 	}
    //
    // 	// console.log(this.current.info)
    // },
    runStory(auto) {
      if (!this.current.storyId) {
        this.timeline.value = this.timeline.min;
        this.map.setView(this.history.start.coords, this.history.start.zoom);
      }

      const curIndex = this.history.story.findIndex(story => story.id === this.current.storyId),
          curStory = this.history.story[curIndex],
          prevStory = this.history.story[curIndex - 1] ? this.history.story[curIndex - 1] : this.history.story[this.history.story.length - 1],
          nextStory = this.history.story[curIndex + 1] ? this.history.story[curIndex + 1] : this.history.story[0],
          steps = Math.abs(prevStory.timeline - curStory.timeline),
          timeWait = (1000 * curStory.duration) / (steps + 1);

      let teller = 0;

      this.clearSetTimeout();

      for (let timeline = prevStory.timeline; timeline <= curStory.timeline; timeline++) {
        ((timeline) => {
          this.timeouts.push(setTimeout(() => {
            // console.log(timeline, timeWait, timeWait * teller);
            this.timeline.value = timeline;
          }, timeWait * teller));
        })(timeline);
        teller++;
      }

      // console.log(auto, !auto ? 1 : curStory.duration, this.autoRun);

      // console.log('storystart', prevStory.timeline, curStory.timeline, nextStory.timeline, steps);
      this.map.flyTo(curStory.coords, curStory.zoom, {
        duration: (!auto ? 1 : curStory.duration),
      }).once('moveend', () => {
        if (teller == 0) {
          this.timeline.value = curStory.timeline;
        }
        // this.showContent(curStory.text);
        this.current.story = curStory;
        setTimeout(() => {

          if (this.autoRun) {
            this.current.storyId = nextStory.id;
            this.runStory(true);
          }
        }, curStory.wait);
      });
    },
  },
}
</script>
